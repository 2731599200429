@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write you own custom component styles here */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

@layer components {
}

.devProgressFull{
color: #fff
}

.devProgressMobile{
    color: white;
    writing-mode: vertical-lr;
}
html,
body, #__next {
  width: 100%;
  height: 100%;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #131418;
}

* {
  font-family: Mulish;
  font-style: normal;

  /* outline: 1px solid #f00 !important; */
}

::selection{
  background: #3f4451;
}

img::selection {
  background: transparent;
}

div::selection {
  background: transparent;
}

::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #181b21;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 7.5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #616776;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4),
    inset 1px 0px 3px rgba(255, 255, 255, 0.2),
    inset -1px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 7.5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8b2ca;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4),
    inset 1px 0px 3px rgba(255, 255, 255, 0.2),
    inset -1px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 7.5px;
}

::placeholder {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;

  color: #3f4451;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;

  color: #3f4451;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;

  color: #3f4451;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@keyframes background-pan {
  0% {
    background-position: 0% center;
    text-shadow: 0px 0px 15px rgba(192, 34, 44, 0.5);
  }

  25%{
    text-shadow: 0px 0px 30px rgba(255, 153, 0, 0.5);
  }

  50% {
    background-position: -200% center;
    text-shadow: 0px 0px 15px rgba(192, 34, 44, 0.5);
  }

  100% {
    background-position: 0% center;
    text-shadow: 0px 0px 15px rgba(192, 34, 44, 0.5);
  }
}

.Buzzword {
  animation: background-pan 3s linear infinite;
  background: linear-gradient(to right, #C0222C 50%, #FF9900 75%, #C0222C 100%);
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 0px 20px rgba(192, 34, 44, 0.8);
}